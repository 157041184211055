<template>
  <div class="logo" @click="handleClick">
    <a v-if="isClickable" href="#">
      <img :src="defaultLogo" alt="Logo" class="logo-img" />
    </a>
    <template v-else>
      <img :src="defaultLogo" alt="Logo" class="logo-img" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import defaultLogo from '@/images/logo-vertical.svg'; // Path to your default logo

export default defineComponent({
  name: 'TeamForgeAILogo',
  props: {
    isClickable: Boolean,
  },
  data() {
    return {
      defaultLogo,
    };
  },
  methods: {
    handleClick() {
      if (this.isClickable) {
        this.$emit('logo-clicked');
      }
    },
  },
});
</script>

<style scoped>
.logo-img {
  /* Default styles for logo images (mobile-first) */
  width: 100%; /* Example - adjust as needed */
  max-width: 600px; /* Limiting the size on mobile devices */
  height: auto; /* Maintain aspect ratio */
}

/* Styles for the logo container */
.logo {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

img {
  filter: drop-shadow(2px 2px 5px rgb(0, 0, 0, 0.5));
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .logo-img {
    /* Adjust the logo size for larger screens */
    max-width: 800px; /* Example - adjust as needed */
    /* Optionally, switch to a different logo image */
    content: url('@/images/logo-full.svg'); /* Replace with the path to your larger logo */
  }
}

@media (orientation: landscape) {
  .logo-img {
    /* Adjust the logo size for larger screens */
    max-width: 800px; /* Example - adjust as needed */
    /* Optionally, switch to a different logo image */
    content: url('@/images/logo-full.svg'); /* Replace with the path to your larger logo */
  }
}

</style>
