<template>
  <div class="home-container">
    <div class="grid-container">
      <div class="early-access">
        <!-- Early access button -->
        <div v-show="toggles.btnEarlyAccess" :style="{ opacity: visibleToggles.btnEarlyAccess ? 1 : 0 }"
          class="btn-early-access inline-flex relative before:absolute before:inset-0 before:bg-purple-500 before:blur-md top-button">
          <a :class="btnEarlyAccessAnchorClass" href="/signin">
            <span class="relative inline-flex items-center title">
              <span v-html="earlyAccessButtonText"></span> <span
                class="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
            </span>
          </a>
        </div>
      </div>
      <div class="logo">
        <TeamForgeAILogo v-show="toggles.logo" :style="{ opacity: visibleToggles.logo ? 1 : 0 }" />
      </div>
      <main class="description">
        <p v-show="toggles.description" class="description-text" :style="{ opacity: visibleToggles.description ? 1 : 0 }">
          {{ description }}</p>
      </main>
      <div class="cta">
        <Transition name="fade">
          <div v-show="toggles.cta" class="cta" :style="{ opacity: visibleToggles.cta ? 1 : 0 }">
            <!-- Individual button containers -->
            <div>
              <!-- Button with text and background styles, hover effects, and transition properties -->
              <a class="btn text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white w-full transition duration-150 ease-in-out group"
                href="/signin">
                Get Started <span
                  class="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
              </a>
            </div>
            <div>
              <!-- Another button with different text and background styles -->
              <a class="btn text-slate-200 hover:text-white bg-slate-900 bg-opacity-25 hover:bg-opacity-30 w-full transition duration-150 ease-in-out"
                href="/signin?next=docs">
                <!-- SVG icon with fill and margin styles -->
                <svg class="shrink-0 fill-slate-300 mr-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path
                    d="m1.999 0 1 2-1 2 2-1 2 1-1-2 1-2-2 1zM11.999 0l1 2-1 2 2-1 2 1-1-2 1-2-2 1zM11.999 10l1 2-1 2 2-1 2 1-1-2 1-2-2 1zM6.292 7.586l2.646-2.647L11.06 7.06 8.413 9.707zM0 13.878l5.586-5.586 2.122 2.121L2.12 16z" />
                </svg>
                <!-- Text label for the button -->
                <span>Read the docs</span>
              </a>
            </div>
          </div>
        </Transition>
      </div>
      <div class="footer">
        <div v-show="toggles.footer" class="footer" :style="{ opacity: visibleToggles.footer ? 1 : 0 }">
          <a href="mailto:interest@teamforge.ai">contact TeamForge AI</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Transition } from 'vue';
import TeamForgeAILogo from '../partials/TeamForgeAILogo.vue'
export default {
  name: 'Home',
  components: {
    TeamForgeAILogo,
    Transition
  },
  mounted() {
    const times: Record<string, number> = {};
    if (localStorage.getItem('firstVisit') === null) {
      localStorage.setItem('firstVisit', 'true');
      times.logo = 250;
      times.description = 450;
      times.cta = 1050;
      times.footer = 650;
    } else {
      times.logo = 450;
      times.description = 850;
      times.cta = 400;
      times.footer = 600;
    }

    setTimeout(() => {
      this.visibleToggles.logo = true;
    }, times.logo);
    setTimeout(async () => {
      if (localStorage.getItem('typed') === null) {
        this.visibleToggles.description = true;
        localStorage.setItem('typed', 'true');
        await this.type(0);
      } else {
        this.description = this.descriptionFull.trim();
        this.visibleToggles.description = true;
      }
      setTimeout(() => {
        this.visibleToggles.btnEarlyAccess = true;
        this.visibleToggles.cta = true;
      }, times.cta);
      setTimeout(() => {
        this.visibleToggles.footer = true;
      }, times.footer);
    }, times.description);
  },
  data: () => {
    return {
      // title: "TeamForge AI",
      earlyAccessButtonText: 'TeamForge AI is now in early access',
      btnEarlyAccessAnchorClass: 'btn-med px-5 py-2 text-slate-300 hover:text-white transition duration-500 ease-out group [background:linear-gradient(theme(colors.purple.500), theme(colors.purple.500)) padding-box, linear-gradient(theme(colors.purple.500), theme(colors.purple.200) 75%, theme(colors.transparent) 100%) border-box] relative before:absolute before:inset-0 before:bg-slate-900/75 before:rounded-full before:pointer-events-none shadow',
      descriptionFull:
        `
        Pioneering uncharted territory in Artificial Intelligence, TeamForge AI
        transforms enigmatic technology into a revolutionary force-multiplying solution.
        `,
      description: '',
      toggles: {
        btnEarlyAccess: true,
        logo: true,
        description: true,
        footer: true,
        cta: true
      },
      visibleToggles: {
        btnEarlyAccess: false,
        logo: false,
        description: false,
        footer: false,
        cta: false
      }
    };
  },
  methods: {
    type(index = 0, interval = 50) {
      return new Promise<void>((resolve) => {
        const _type = (index: number) => {
          if (index < this.descriptionFull.length) {
            this.description += this.descriptionFull[index];
            setTimeout(() => _type(index + 1), interval);
          } else {
            resolve(); // Resolve the promise when typing is complete
          }
        };

        _type(index);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
$debug: false;

@if $debug {
  .home-container {
    border: 3px dashed green;
  }

  .grid-container {
    border: 3px dashed blue;
  }

  .content {
    border: 3px dashed yellow;
  }

  .logo {
    border: 1px solid white;
  }

  .description {
    border: 3px dashed chocolate;
  }

  .cta {
    border: 3px dotted chartreuse;
  }

  .footer {
    border: 3px dotted pink;
  }
}

* {
  transition: opacity 0.5s ease;
}

.home-container {
  height: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 1fr 1fr min-content;
  gap: 2rem;
  grid-auto-flow: row;
  grid-template-areas:
    "early-access"
    "logo"
    "description"
    "cta"
    "footer";
  width: 100%;
  height: 100%;

  @media (orientation: landscape) {
    gap: 1.8rem;
  }
}

.btn-med {
  cursor: pointer;
}

.early-access {
  justify-self: center;
  align-self: end;
  grid-area: early-access;
  margin-top: clamp(1%, 1.2vh, 3%);

  @media (orientation: landscape) {
    font-size: 0.7rem;
  }
}

.logo {
  justify-self: center;
  align-self: center;
  grid-area: logo;
  width: min(100%, 600px);

  @media (orientation: landscape) {
    width: min(100%, 550px);
    // @media(min-width: 768px) {
    //   border: 1px solid white;
    //   width: min(100%, 550px);
    // }
  }
}

.description {
  justify-self: center;
  align-self: stretch;
  grid-area: description;
  max-width: 550px;
  text-align: center;
  filter: drop-shadow(2px 2px 2px rgb(0, 0, 0, 0.8));
  font-size: clamp(1rem, 2.3vw, 2rem);

  @media (orientation: landscape) {
    font-size: 0.9rem;
  }
}

.cta {
  grid-area: cta;
  display: flex;
  justify-self: center;
  align-self: start;
  justify-content: space-between;
  min-width: 300px;

  @media (orientation: landscape) {
    * {
      font-size: 0.8rem;
    }
  }
}

.footer {
  justify-self: center;
  align-self: start;
  grid-area: footer;

  @media (orientation: landscape) {
    position: relative;
    top: -5px;
  }
}
</style>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
