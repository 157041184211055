<template>
  <div class="app-container">
    <Particles v-if="toggles.particles" class="absolute inset-0 -z-10" :quantity="100" />
    <router-view v-if="toggles.router" />
    <ul v-if="toggles.test">
      <li v-for="i in numMockItems" :key="i">
        item {{ i }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import AOS from 'aos'
import Particles from './partials/Particles.vue'

const aosInit = () => AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

export default {
  components: {
    Particles,
  },
  mounted() {
    aosInit();
  },
  data() {
    return {
      title: 'TeamForge AI',
      numMockItems: 500,
      toggles: {
        particles: true,
        router: true,
        test: false
      }
    };
  }
}
</script>

<style lang="scss">
body {
  background-color: black !important;
  /* Prevent horizontal scrolling - mobile */
  overflow-x: hidden;
}
</style>

<style lang="scss" scoped>
$debug: false;

@if $debug {
  .app-container {
    border: 1px solid red;
  }
}

.app-container {
  background-image: url('./images/glow-bottom.svg');
  background-size: cover;
  background-position: bottom;
  height: 100%;
  min-width: 390px;
  padding: min(10%, 22px);
  overflow-y: scroll
}
</style>
